const screens = {
  xs: "420px",
  sm: "576px",
  md: "768px",
  mobile: "700px",
  lg: "992px",
  xl: "1200px",
  xxl: "1400px",
};

export default screens;
